import React from 'react';
import './Accommodation.css';
import PageTitle from '../components/PageTitle/PageTitle';

const Accommodation = () => {
  return (
    <>
      <PageTitle title={'Accommodation'} />
      <div className="accommodation-container"> {/* Change this class name */}
        <div className="box">
          Accommodation can be booked at Ribby Hall Village for the night before and night of the wedding.
          <br /> <br />We have been allocated a set amount of properties and the motel for our wedding guests to book, and this can be done by contacting Katie or Chris with your details and accommodation wanted. We will then collate this information for the team at Ribby, who will contact you directly around 6 months before the wedding date to arrange payment and give you all other details you require.
        </div>

        <div className="box">
          5 x Poppy Cottages.  <br /> These are one storey properties that sleep up to 6 guests.<br /> £249 per cottage per night. <br />Check in from 4pm and check out by 10am.
        </div>

        <div className="box">
          2 x Lavender Cottages.<br /> These are one storey properties that sleep up to 4 guests.<br /> £205 per cottage per night. <br />Check in from 4pm and check out by 10am.
        </div>

        <div className="box">
          8 x Motel Rooms.<br />  4 x Double Rooms and 4 x Twin rooms.<br />  £80 per room per night.<br /> Check in from 2pm and check out by 12noon.
        </div>
      </div>
    </>
  );
}

export default Accommodation;

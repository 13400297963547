import './NavBar.css';
import { Link } from "react-router-dom";
import ScreenSize from '../../hooks/Screensize';
import { useEffect, useState } from 'react';
import Hamburger from 'hamburger-react'

const Navbar = ({ children }) => {
    const screenSize = ScreenSize()
    const [isOpen, setOpen] = useState(false)


    useEffect(() => {
        console.log(screenSize)
    }, [screenSize])
    return (
        <nav>
            <div
                className='navContainer'
                style={
                    {
                        justifyContent: screenSize === 'desktop'
                            ? 'space-around'
                            : 'space-between',
                            maxHeight:"40px"
                    }}>
                {screenSize === 'desktop' ? <>
                    <Link to='/' className='link'>
                        Home
                    </Link>
                    <Link to='/about-the-couple' className='link'>
                        About the Couple
                    </Link>
                    <Link to='/agenda' className='link'>
                        Agenda
                    </Link>
                    <Link to='/table-plan' className='link'>
                        Table Plan
                    </Link>
                    <Link to='/guest-list' className='link'>
                        Guest list
                    </Link>
                    <Link to='/Accommodation' className='link'>
                        Accommodation
                    </Link>
                    <Link to='/upload-a-photo' className='link'>
                        Upload a photo
                    </Link>
                </> : <><Hamburger toggled={isOpen} toggle={setOpen} /><div className='titleText'>Chris and Katies Wedding</div><div></div></>}
            </div>
            <div className='sideMenu' style={{ left: !isOpen ? '-205px' : 0}}>
                <Link to='/' className='link' onClick={()=> setOpen(false)}>
                    Home
                </Link>
                <Link to='/about-the-couple' className='link' onClick={()=> setOpen(false)}>
                    About the Couple
                </Link>
                <Link to='/agenda' className='link' onClick={()=> setOpen(false)}>
                    Agenda
                </Link>
                <Link to='/table-plan' className='link' onClick={()=> setOpen(false)}>
                    Table Plan
                </Link>
                <Link to='/guest-list' className='link' onClick={()=> setOpen(false)}>
                    Guest list
                </Link>
                <Link to='/Accommodation' className='link' onClick={()=> setOpen(false)}>
                    Accommodation
                </Link>
                <Link to='/upload-a-photo' className='link' onClick={()=> setOpen(false)}>
                    Upload a photo
                </Link>
            </div>
            <div style={{position: 'absolute', top: '90px', left: 0, width: '100%'}}>
                {children}

            </div>
        </nav>
    );
}

export default Navbar;
import React, { useState, useEffect } from 'react';
import './slideshow.css'

const Slideshow = ({ images, close }) => {
    const [imageStatus, setImageStatus] = useState('fade-in')
    const [index, setIndex] = useState(0)

    useEffect(() => {
        if (imageStatus === 'fade-in') {
            setTimeout(() => {
                setImageStatus('fade-out')
            }, 2000)
        }
        if (imageStatus === 'fade-out') {
            setTimeout(() => {
                if (index === images.length - 1) setIndex(0)
                else setIndex(index + 1)
                setImageStatus('fade-in')
            }, 1000)
        }
        // eslint-disable-next-line 
    }, [imageStatus])
    
    return (
        <div
            onClick={close}
            style={{
                position: 'fixed',
                top: 0, height: '100%',
                width: '100%',
                backgroundColor: 'black',
                zIndex: 2000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <img className='fade' style={{ opacity: imageStatus === 'fade-in' ? 1 : 0 }} src={images[index]} height={'80%'} alt='current wedding frame' />
        </div>
    )
}

export default Slideshow;
import "./App.css";
import RouteComponent from "./Routes/RouteComponent";
import Navbar from './components/NavBar/Navbar';
import Background from "./images/Background.jpg"
import Countdown from "./components/CountDownClock";

const App = () => {
  const targetDate = new Date('May 30, 2024 12:59:59');
  return (
    <>
    <div><Countdown targetDate={targetDate} /></div>
    <div className="container">
      <Navbar />
      <RouteComponent  />
      <div>
      <img className="backgroundImage" src={Background} alt="Background" />
      </div>
    </div>
    </>
  );
};

export default App;

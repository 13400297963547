import React, { useState } from 'react';
import './GuestList.css';
import PageTitle from '../components/PageTitle/PageTitle';
import images from '../imageLoader';

const imagesWithText = [
  { url: images['./Alan_H_Pic.jpg'], text: 'Alan'},
  { url: images['./Angie_Pic.jpg'], text: 'Angie'},
  { url: images['./Anthony_Pic.jpg'], text: 'Anthony'},
  { url: images['./Austin_Pic.jpg'], text: 'Austin'},
  { url: images['./Bjorn_Pic.jpg'], text: 'Bjorn'},
  { url: images['./Chris_Pic.jpg'], text: 'Chris Arkinstall'},
  { url: images['./Craig_Pic.jpg'], text: 'Craig'},
  { url: images['./David_Pic.jpg'], text: 'David'},
  { url: images['./Donna_Pic.jpg'], text: 'Donna'},
  { url: images['./Debs_Pic.jpg'], text: 'Deb\'s'},
  { url: images['./Dom_Pic.jpg'], text: 'Dom'},
  { url: images['./Emma_Pic.jpg'], text: 'Emma'},
  { url: images['./Hannah_Pic.jpg'], text: 'Hannah'},
  { url: images['./Hughie_Pic.jpg'], text: 'Hughie'},
  { url: images['./Jane_Pic.jpg'], text: 'Jane'},
  { url: images['./Janet_Pic.jpg'], text: 'Janet'},
  { url: images['./Katie_Pic.jpg'], text: 'Katie Henshall (Arkinstall)'},
  { url: images['./Leanne_Pic.jpg'], text: 'Leanne'},
  { url: images['./Lucas_Pic.jpg'], text: 'Lucas'},
  { url: images['./Mark_Pic.jpg'], text: 'Mark'},
  { url: images['./Millie_Pic.jpg'], text: 'Amelia-Rose'},
  { url: images['./Olivia_Pic.jpg'], text: 'Olivia'},
  { url: images['./Peter_Pic.jpg'], text: 'Peter'},
  { url: images['./Richard_Pic.jpg'], text: 'Richard'},
  { url: images['./Rick_Pic.jpg'], text: 'Rick'},
  { url: images['./Riley_Pic.jpg'], text: 'Riley'},
  { url: images['./Ruban_Pic.jpg'], text: 'Ruban'},
  { url: images['./Seb_Pic.jpg'], text: 'Seb'},
  { url: images['./Skyla_Pic.jpg'], text: 'Skyla'},
  { url: images['./Sophie_Pic.jpg'], text: 'Sophie'},
  { url: images['./Susan_Pic.jpg'], text: 'Susan'},
  { url: images['./Tegan_Pic.jpg'], text: 'Tegan'},
  
];

function TablePlan() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <PageTitle title={'Guest List'} />
      <div className="image-gallery">
        {imagesWithText.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image.url} alt={`${index}`} onClick={() => openImage(image)}/>
            <div className="image-caption">{image.text}</div>
          </div>
        ))}
        {selectedImage && (
          <div className="image-modal" onClick={closeImage}>
            <div className="image-modal-content">
              <img src={selectedImage.url} alt="Selected" />
              <div className="image-caption">{selectedImage.text}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TablePlan;

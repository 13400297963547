import React from 'react';
import './Agenda.css';
import PageTitle from '../components/PageTitle/PageTitle';
import './Home.css';

const Agenda = () => {
  return (
    <>
      <PageTitle title={'Agenda'} />
      <div>
        <div className='event'>
          <div>12:00 <br></br>Guests arrive</div>
          
        </div>

        <div className='event'>
          <div>12:30<br></br>Guests take their seats in the Rowan Suite</div>
          
        </div>

        <div className='event'>
          <div>13:00<br></br>The wedding ceremony begins</div>
          
        </div>

        <div className='event'>
          <div>13:30<br></br>Drinks and canapés</div>
      
        </div>

        <div className='event'>
          <div>14:00<br></br>Photographs</div>
      
        </div>

        <div className='event'>
          <div>16:00<br></br>Break</div>
          
        </div>

        <div className='event'>
          <div>19:00 to 00:00<br></br>Let's party!</div>
          
        </div>

      </div>
    </>
  );
}

export default Agenda;

import './imagHolder.css'


const ImageHolder = ({photo, size = 100, text = 's', isSelect, onClick}) => {

    return (
        <div className="ImgText" style={{width: `${size}px`, transform: isSelect ? 'scale(1.5)': 'scale(1)', borderRadius: `${size / 2}px`,}} onClick={onClick}>
                <img
                    alt={photo}
                    style={
                        {
                            height: size,
                            borderRadius: `${size / 2}px`,
                            objectFit: 'cover',
                            overflow: 'hidden',
                            border: '2px solid #FFFFF0'
                        }
                    }
                    src={photo}/>
                   {isSelect &&
                    <div 
                        className='textBox'
                        style={{top: size, width: size, fontsize: 8 }}>
                        {text}
                    </div>}
              
        </div>
    );
}

export default ImageHolder;
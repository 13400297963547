import React, { useState } from 'react';
import ImageHolder from '../components/ImageHolder/ImageHolder';
import PageTitle from '../components/PageTitle/PageTitle';
import { mainTable, table1, table2, table3, table4, table5, table6 } from './data';
import Table from './Table';
import './TablePlan.css';

const TablePlan = () => {
  const [openImageIndex, setOpenImageIndex] = useState(null);

  const handleImageClick = (index) => {
    if (openImageIndex === index) {
      setOpenImageIndex(null);
    } else {
      setOpenImageIndex(index);
    }
  };



  return (
    <>
      <PageTitle title={'Table Plan'} />
      <div className="wedding-table-plan">
        <>
          <div className="circle-row">
            {mainTable.map((guest, i) => {
              return (
                <div
                  onClick={() => {
                    // if (i === 1 || i === 2){
                    // confetti({
                    //   particleCount: 500,
                    //   spread: 70
                    // })}
                  }}>
                  <ImageHolder
                    key={i}
                    size={50}
                    photo={guest.src}
                    text={guest.text}
                    onClick={() => handleImageClick(i)}
                    isSelect={openImageIndex === i}
                  />
                </div>
              );
            })}
          </div>
          <div className='rectangleTable'>Better together</div>
          <div style={{
            position: 'relative',
            width: '100%',
            height: 'max-content',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>

            {[table1, table2, table3, table4, table5, table6].map((table, index, i) => {
              return <Table
                key={index}
                table={table}
                size={50}
                onClick={() => handleImageClick(index)}
                isSelect={openImageIndex === index} />;
            })}

          </div>
        </>
      </div>
    </>
  );
};

export default TablePlan;

//Head Table
import './TablePlan.css';
import Katie_Pic from '../images/Katie_Pic.jpg';
import Chris_Pic from '../images/Chris_Pic.jpg';
import Emma_Pic from '../images/Emma_Pic.jpg';
import Rick_Pic from '../images/Rick_Pic.jpg';
import Peter_Pic from '../images/Peter_Pic.jpg';

//Double Trouble
import Alan_H_Pic from '../images/Alan_H_Pic.jpg';
import Janet_Pic from '../images/Janet_Pic.jpg';
import Susan_Pic from '../images/Susan_Pic.jpg';
import Mark_Pic from '../images/Mark_Pic.jpg';
import Millie_Pic from '../images/Millie_Pic.jpg';
import Olivia_Pic from '../images/Olivia_Pic.jpg';

// Lucky
import Donna_Pic from '../images/Donna_Pic.jpg';
import David_Pic from '../images/David_Pic.jpg';
import AlanB_Pic from '../images/AlanB_Pic.jpg';
import Carol_Pic from '../images/Carol_Pic.jpg';

//Perfect
import Anthony_Pic from '../images/Anthony_Pic.jpg';
import Leanne_Pic from '../images/Leanne_Pic.jpg';
import Tegan_Pic from '../images/Tegan_Pic.jpg';
import Riley_Pic from '../images/Riley_Pic.jpg';
import Seb_Pic from '../images/Seb_Pic.jpg';
import Skyla_Pic from '../images/Skyla_Pic.jpg';

//This is the life
import Jane_Pic from '../images/Jane_Pic.jpg';
import Hannah_Pic from '../images/Hannah_Pic.jpg';
import Brandon_Pic from '../images/Brandon_Pic.jpg';
import Austin_Pic from '../images/Austin_Pic.jpg';
import Richard_Pic from '../images/Richard_Pic.jpg';


//It must be love
import Angie_Pic from '../images/Angie_Pic.jpg';
import Ruban_Pic from '../images/Ruban_Pic.jpg';
import Dom_Pic from '../images/Dom_Pic.jpg';
import Lucas_Pic from '../images/Lucas_Pic.jpg';
import Plus_One_Pic from '../images/Plus_One_Pic.jpg';

//Stand By Me
import Craig_Pic from '../images/Craig_Pic.jpg';
import Hughie_Pic from '../images/Hughie_Pic.jpg';
import Bjorn_Pic from '../images/Bjorn_Pic.jpg';
import Sophie_Pic from '../images/Sophie_Pic.jpg';
import Debs_Pic from '../images/Debs_Pic.jpg';






export const mainTable = [
    { src: Rick_Pic, text: 'Rick' },
    { src: Chris_Pic, text: 'Chris Arkinstall' },
    { src: Katie_Pic, text: 'Katie Henshall (Arkinstall)' },
    { src: Emma_Pic, text: 'Emma' },
    { src: Peter_Pic, text: 'Peter' },
]
//this is a table
export const table1 = {
    guests: [
        { src: Alan_H_Pic, text: 'Alan' },
        { src: Janet_Pic, text: 'Janet' },
        { src: Susan_Pic, text: 'Susan' },
        { src: Mark_Pic, text: 'Mark' },
        { src: Millie_Pic, text: 'Amelia' },
        { src: Olivia_Pic, text: 'Olivia' },
    ],
    name: 'Double Trouble'
}
export const table2 = {
    guests: [
        { src: David_Pic, text: 'David' },
        { src: Donna_Pic, text: 'Donna' },
        { src: AlanB_Pic, text: 'Alan' },
        { src: Carol_Pic, text: 'Carol' },
        { src: "Martyn", text: 'Martyn)' },
        { src: "Pat", text: 'Pat' },
    ],
    name: 'Lucky'
}
export const table3 = {
    guests: [
        { src: Anthony_Pic, text: 'Anthony' },
        { src: Leanne_Pic, text: 'Leanne' },
        { src: Seb_Pic, text: 'Seb' },
        { src: Tegan_Pic, text: 'Teagan' },
        { src: Riley_Pic, text: 'Riley' },
        { src: Skyla_Pic, text: 'Sklya' },
    ],
    name: 'Perfect'
}
export const table4 = {
    guests: [
        { src: Craig_Pic, text: 'Craig' },
        { src: Hughie_Pic, text: 'Hughie' },
        { src: Sophie_Pic, text: 'Sophie' },
        { src: Debs_Pic, text: 'Deb\'s' },
        { src: Bjorn_Pic, text: 'Bjorn' },
    ],
    name: 'Stand By Me'
}
export const table5 = {
    guests: [
        { src: Jane_Pic, text: 'Jane' },
        { src: Hannah_Pic, text: 'Hannah' },
        { src: Brandon_Pic, text: 'Brandon' },
        { src: Austin_Pic, text: 'Austin' },
        { src: Richard_Pic, text: 'Richard' },
    ],
    name: 'This Is The Life'
}
export const table6 = {
    guests: [
        { src: Angie_Pic, text: 'Angie' },
        { src: Ruban_Pic, text: 'Ruban' },
        { src: Dom_Pic, text: 'Dom' },
        { src: Lucas_Pic, text: 'Luca' },
        { src: Plus_One_Pic, text: 'Angie +1' },
    ],
    name: 'It Must Be Love'
}

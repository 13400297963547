import AWS from 'aws-sdk';
import { useState, useEffect } from 'react'
import './UploadAPhoto.css';
import photoImg from '../images/photo_camera.svg'
import Slideshow from '../components/SlideShow/SlideShow';
import slideShowImage from '../images/presentation.png'
import ImageHolder from '../components/ImageHolder/ImageHolder';
import PageTitle from '../components/PageTitle/PageTitle';

const { REACT_APP_ACCESS_KEY_ID, REACT_APP_SECRET_ACCESS_KEY } = process.env

AWS.config.update({
  accessKeyId: REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: REACT_APP_SECRET_ACCESS_KEY,
  region: 'eu-west-2',
  signatureVersion: 'v4',
});

const params = {
  Bucket: 'weddingbucket',
  Delimiter: '',
  Prefix: '',
};

const UploadAPhoto = () => {
  const s3 = new AWS.S3();
  const [files, setFiles] = useState([]);
  const [listFiles, setListFiles] = useState([])
  const [showSlideShow, setShowSlideShow] = useState(false)


  const handleFileSelect = (e) => {
    setFiles([...e.target.files]);
  }

  const uploadToS3 = async (image) => {
    if (!image) {
      return;
    }
    const params = {
      Bucket: 'weddingbucket',
      Key: `${Date.now()}.${image.name}`,
      Body: image
    };
    const { Location } = await s3.upload(params).promise();
    setListFiles([...listFiles, Location]);
  }

  const handleFilesUpload = () => {
    let newArr = files
    for (let i = 0; i < newArr.length; i++) {
      uploadToS3(newArr[i]);
    }
  };

  const startFullscreen = () => {
    document.body.requestFullscreen();
    setShowSlideShow(true)
  }

  const getImagesFromS3 = () => {
    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        setListFiles(data.Contents);
        const photos = data.Contents.map((d) => {
          return `https://weddingbucket.s3.eu-west-2.amazonaws.com/${d.Key}`
        })
        setListFiles([...photos.filter((photo) => !listFiles.includes(photo))])
      }
    });
  }

  useEffect(() => {
    getImagesFromS3()
  }, [])

  useEffect(() => {
    setInterval(() => {
      getImagesFromS3()
    }, 30000)
  }, []);// eslint-disable-line

  useEffect(() => {
    handleFilesUpload()
  }, [files])// eslint-disable-line

  return (
    <>
    <PageTitle title={'Upload your Photo\'s'}/>
    <div style={{ marginTop: '114px' }}>
      <div className='buttonContainer'>
        <div className='buttonDiv'>
          <input multiple id='imageUpload' type="file" onChange={handleFileSelect} height={'80px'}/>  
          <ImageHolder photo={photoImg} size={100}/>
        </div>
        <div onClick={() => startFullscreen()} style={{marginLeft: '32px'}}>
          <ImageHolder photo={slideShowImage} size={100} />
        </div>
      </div>
      {showSlideShow && <Slideshow images={listFiles} close={() => setShowSlideShow(false)}/>}
      <div className='gallery'>
        {listFiles.map((photo) => {
          return (
            <div style={{maxHeight: '200px', margin: '24px'}}>
              <img
                className='frame'
                src={photo}
                alt="uploaded"
                height={'200px'} />

            </div>
          )
        })}
      </div>
    </div>
    </>
  );
}

export default UploadAPhoto;
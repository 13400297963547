import {useEffect, useState} from 'react'

const ScreenSize = () => {
    const [screenType, setScreenType] = useState('') 

    const selectScreenSize = (x, y) => {
        if (x < 640) return setScreenType('mobile')
        if (x >= 640 && x < 896) return setScreenType('tablet')
        else return setScreenType('desktop')
    }

  useEffect(() => {
    const handleResize = () => {
      selectScreenSize(window.innerWidth, window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
  }, [])




  return screenType
}

export default ScreenSize;
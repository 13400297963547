import ImageHolder from '../components/ImageHolder/ImageHolder';
import { useState } from 'react';
import './table.css';

const Table = ({ table }) => {
  const [isClicked, setIsClicked] = useState(null);

  const calculatePosition = (index, totalImages) => {
    const angle = (360 / totalImages) * index;
    const radius =120;

    const x = Math.round(radius * Math.cos((angle * Math.PI) / 180));
    const y = Math.round(radius * Math.sin((angle * Math.PI) / 180));

    return {
      top: `${50 + y}px`,
      left: `${50 + x}px`,
    };
  };

  const toggleClicked = (index) => {
    if (isClicked === index) {
      setIsClicked(null);
    } else {
      setIsClicked(index);
    }
  };

  return (
    <div className='tableContainer' style={{ position: 'relative' }}>
      <div className='table'></div>
      {table.guests.map((guest, i) => (
        <div key={i} style={{ position: 'absolute', ...calculatePosition(i, table.guests.length) }}>
          <ImageHolder
            size={60}
            photo={guest.src}
            text={guest.text}
            onClick={() => toggleClicked(i)}
            isSelect={isClicked === i}
          />
        </div>
      ))}
      <div style={{ position: 'absolute' }}>{table.name}</div>
    </div>
  );
};

export default Table;

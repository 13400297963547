import './AboutTheCouple.css';
import PageTitle from '../components/PageTitle/PageTitle';
import "./Home.css";


const AboutTheCouple = () => {

    return (
      <>
        <PageTitle title={'Our Story'}/>
        <div className='text_box'>
        Once upon a time in the midst of the Covid-19 pandemic, fate brought together two souls on the digital realm of Tinder. Chris and Katie's love story began in May 2020, where swipes turned into conversations, and conversations into a deep connection that transcended the challenges of the times.
<br></br>
<br></br>
Chris, a loving father to the sweet Amelia, found in Katie not just a partner but a second mum who effortlessly won Amelia's heart. Their journey as a blended family started with genuine smiles, shared laughter, and an abundance of love that quickly made Katie an integral part of their lives.
<br></br>
<br></br>
As the pandemic storm subsided, Chris and Katie decided to build a life together. They embarked on the exciting journey of cohabitation, moving into a bigger home that echoed with the joyous laughter of their growing family. Their love story took a new chapter with the arrival of their precious little Olivia, a testament to the love that flourished between them.
<br></br>
<br></br>
Amelia, now having two incredible role models in her life, continued to blossom under the care of Chris and Katie. The family expanded, and the walls of their home echoed with the pitter-patter of tiny feet and the warmth of shared dreams.
<br></br>
<br></br>
Chris and Katie, with hearts intertwined and dreams aligned, are excitedly looking forward to completing their family. Through the ups and downs, they've found strength in each other, creating a haven of love and support. As they stand on the threshold of forever, their story is a testament to the resilience of love, the beauty of blended families, and the joy that comes with building a life together. Cheers to the past, present, and the beautiful future that awaits this loving couple and their growing family!
        </div>
        </>
    );
}

export default AboutTheCouple;
import React, { useState, useEffect } from 'react';


const Countdown = ({ targetDate }) => {
  const [isVisible, setIsVisible] = useState(true)
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (isVisible && (targetDate <= new Date(targetDate) - new Date())) {
      return setIsVisible(false)
    }
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  return (
    <>
      {isVisible && <div className='countDownContainer'>
        {timeLeft.days > 0 && <div>{timeLeft.days} days</div>}
        <div>{timeLeft.hours} hours</div>
        <div>{timeLeft.minutes} minutes</div>
        <div>{timeLeft.seconds} seconds</div>
      </div>}
    </>
  );
};

export default Countdown



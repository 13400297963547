import { Routes, Route } from "react-router-dom";
import Home from '../pages/Home';
import AboutTheCouple from '../pages/AboutTheCouple';
import Accommodation from "../pages/Accommodation";
import GuestList from "../pages/GuestList";
import TablePlan from "../pages/TablePlan";
import UploadAPhoto from "../pages/UploadAPhoto";
import Agenda from "../pages/Agenda";

const RouteComponent = () => {

    return (
        <div style={{height: 'calc(100vh - 82px)', marginTop: '76px'}}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-the-couple" element={<AboutTheCouple />} />
                <Route path="/agenda" element={<Agenda />} />
                <Route path="/table-plan" element={<TablePlan />} />
                <Route path="/guest-list" element={<GuestList />} />
                <Route path="/accommodation" element={<Accommodation />} />
                <Route path="/upload-a-photo" element={<UploadAPhoto />} />
            </Routes>
        </div>

    );
}

export default RouteComponent;
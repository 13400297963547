import PageTitle from "../components/PageTitle/PageTitle";
import "./Home.css";

const Home = () => {
  return (
    <>
      <PageTitle title={'Welcome to the wedding of Chris Arkinstall and Katie Henshall'}/>
      <div className="text_box">
        Welcome to the wedding website of Katie Elizabeth Henshall and Christopher David Mark Arkinstall!
        <br></br>
        <br></br>
        We are thrilled to invite you to share in the joy and excitement as we embark on this beautiful journey together. Here, you'll find all the details you need to be a part of our special day, from the ceremony and reception information to accommodation recommendations and our love story.
        Join us as we celebrate love, unity, and the beginning of our forever. Please feel free to explore the site, get to know our story, and mark your calendars for the joyous occasion that will be etched in our hearts forever.
        <br></br>
        <br></br>
        Thank you for being a part of our lives and for your love and support. We can't wait to create unforgettable memories with you on our wedding day!
        <br></br>
        <br></br>
        With love and gratitude,
        Christopher & Katie
      </div>
    </>
  );
}

export default Home;